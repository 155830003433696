<template>
    <div>
        <div class="mark-tit">
            <p class="mark-tit-p align-left">卡号填什么？</p>
            <p class="mark-tit-b align-left">将社会保障卡（或医保卡）或医院就诊卡的卡号填写到就诊卡输入框，一般情况下填医保卡或者医院就诊卡均可就诊。</p>
        </div>
        <div class="mark-cen">
            <div class="mark-ka">
                <img src="@/assets/images/mark-zhen.png" alt="">
            </div>
            <p class="mark-tit-p">社会保障卡（医保卡）</p>
            <div class="mark-ka">
                <img src="@/assets/images/mark-fang.png" alt="">
            </div>
            <p class="mark-tit-p">社会保障卡（医保卡）</p>
        </div>
        <div class="mark-tit">
            <p class="mark-tit-p c-rose align-left">特别提醒</p>
            <p class="mark-tit-b align-left">部分医院不支持社会保障卡（或医保卡）作为就诊卡，请以实际就诊的医院为准。不同地区的社会保障卡，不同医院的就诊卡的卡面有所不同，下图示例仅供参考，以您实际卡面为准。</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.align-left {
    text-align: left;
}
</style>